@import '../../Styling/colors';

.Jumbotron {
    color: #ffffff;
    display: flex;
    gap: 32px;
    flex-wrap: wrap;
    padding: 64px 64px;

    p {
        box-decoration-break: clone;
        -webkit-box-decoration-break: clone;
        // display: inline;
        padding: 4px;
    }

    &__content-wrapper {
        flex-basis: 600px;
    }

    &__image {
        flex-basis: 300px;
        img {
            width: 100%;
        }
    }

    @media (max-width: 859px) {
        &__image {
            order: 1;
        }
        &__content-wrapper {
            order: 2;
        }
    }

    &__title {
        span {
            color: $gold;
            font-weight: bold;
            font-size: 32px;
        }

        h1 {
            font-size: 48px;
            font-weight: bold;
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    p {
        max-width: 600px;
        font-size: 20px;
        line-height: 1.8em;
    }

    &__button {
        margin-top: 48px;
    }
}