.DocumentList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 32px;

    margin: 0 auto;
    margin-top: -32px;

    max-width: 800px;
}