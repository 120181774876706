@import '../../Styling/colors';

.Wrapper {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        
        // Gradient from black to transparent, left to right
        background: linear-gradient(to bottom right, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 900px);
    }

    div {
        z-index: 1;
    }
}
.Document {
    padding: 32px 64px;

    display: flex;
    flex-wrap: wrap;

    color: #ffffff;

    &__info {
        flex: 1;
        h1 {
            font-size: 48px;
        }

        p {
            max-width: 600px;
            font-size: 20px;
            line-height: 1.8em;
        }
    }

    &__content {
        flex: 1;
    }
}