.Page {
    min-height: calc(100vh - 80px);

    &__loading {
        margin: auto;
    }
}

table.Table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    margin-top: 32px;

    thead {
        border-bottom: 1px solid $black;
        tr {
            th {
                font-size: 20px;
                text-align: center;
            }
        }
    }
    tbody {
        tr {
            &:not(:last-child) {
                border-bottom: 1px solid $black;
            }
            th {
                font-weight: bold;
                padding: 16px;
            }
            td {
                padding: 16px;
            }
        }
    }
}

.Button__list {
    display: flex;
    gap: 16px;
}