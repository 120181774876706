.Wrapper {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        
        // Gradient from black to transparent, left to right
        background: linear-gradient(to bottom right, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 900px);
    }

    div {
        z-index: 1;
    }

    a {
        color: #ffffff;
    }
}