@import '../../Styling/colors';

.Footer {
    padding: 16px 64px;
    display: flex;
    height: 80px;

    flex-wrap: wrap;

    &__logo {
        width: 250px;
        max-width: 100%;

        img {
            width: 100%;
        }
    }

    &__nav {
        flex: 1;
        display: flex;
        gap: 32px;
        justify-content: center;
        padding: 16px;

        a {
            color: $black;
            font-size: 20px;
            text-decoration: none;
            font-weight: 500;
            transition: color 0.2s ease-in-out;
            &:hover {
                color: $gold;
            }
            &.active {
                color: $gold;
            }
        }
    }
}